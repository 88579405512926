window.Prices = {
  coins_symbol: [],
  coins_channel: []
}

window.PriceView = Backbone.View.extend({
  el: 'body',

  initialize(opts) {
    this.parentView = opts.parentView
    this.initCable()
  },

  initCable() {
    if (Prices.coins_symbol.length < 1) {
      return
    }

    for (let coin of Array.from(Prices.coins_symbol)) {
      Prices.coins_channel.push(
        App.cable.subscriptions.create(
          {
            channel: 'CoinsChannel',
            symbol: coin
          },
          {
            received: (data) => {
              return this.receivedCoinPrice(data)
            }
          }
        )
      )
    }
  },

  receivedCoinPrice(json) {
    price_item_class = '.coin_' + json.id + '_price'
    $(price_item_class).html(json.price)

    if (json.change_24h.includes('-')) {
      change_color = '#E02424'
    } else {
      change_color = '#057A55'
    }

    $(`.coin_${json.id}_change_24`).html(
      `<span style="color:${change_color}">${json.change_24h}%</span>`
    )

    volume_item_class = '.coin_' + json.id + '_volume_24h'
    volume_24h_str = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      notation: 'compact',
      compactDisplay: 'short'
    }).format(json.volume_24h)
    $(volume_item_class).html(volume_24h_str)

    $(`.coin_${json.id}_funding_rate`).html(
      `<span style="">${(parseFloat(json.funding_rate) * 100).toFixed(
        3
      )}%</span>`
    )
  }
})
