window.FlexibleTerm = {
  coins_symbol: [],
  channels: []
}

window.FlexibleTermView = Backbone.View.extend({
  el: 'body',
  events: {},

  initialize(opts) {
    this.parentView = opts.parentView
    this.initCable()
  },

  initCable() {
    if (FlexibleTerm.coins_symbol.length < 1) {
      return
    }

    for (let coin of Array.from(FlexibleTerm.coins_symbol)) {
      FlexibleTerm.channels.push(
        App.cable.subscriptions.create(
          {
            channel: 'FlexibleTermChannel',
            symbol: coin
          },
          {
            received: (data) => {
              return this.receivedData(data)
            }
          }
        )
      )
    }
  },

  receivedData(json) {
    $(`.flexible_terms_${json.id}_tb_volumn`).html(
      `<span style="font-size: small;">${json.avgAmtUsd}</span>`
    )

    $(`.flexible_terms_${json.id}_tb_rate`).html(
      `
      <span style="font-size: small;">${(parseFloat(json.avgRate) * 100).toFixed(0)}</span>
      <span style="font-size: small;">${(parseFloat(json.preRate) * 100).toFixed(0)}</span>
      <span style="font-size: small;">${(parseFloat(json.estRate) * 100).toFixed(0)}</span>
      `
    )
  }
})
