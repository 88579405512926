window.ToolsView = Backbone.View.extend({
  el: 'body',
  events: {
    'click button.tool-segemented-trading': function (e) {
      this.showSection('trading', e)
    },
    'click button.tool-segemented-trading-tracker': function (e) {
      this.showSection('trading_tracker', e)
    },
    'click button.tool-segemented-trading-arbitrage': function (e) {
      this.showSection('trading_arbitrage', e)
    }
  },

  initialize(opts) {
    this.parentView = opts.parentView
  },

  showSection(segement, e) {
    const _el = $(e.target).closest('button')
    const btns = document.querySelectorAll('#segmentedContent > button')
    btns.forEach((btn) => {
      btn.classList.remove(
        'bg-white',
        'dark:bg-gray-700',
        'z-10',
        'text-yellow-300'
      )
    })
    _el.addClass('bg-white dark:bg-gray-700 z-10 text-yellow-300')

    const sections = document.querySelectorAll('#segmentedSession > section')
    sections.forEach((section) => {
      if (section.id === segement) {
        section.style.display = ''
      } else {
        section.style.display = 'none'
      }
    })
  }
})
