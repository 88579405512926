window.FundingRate = {
  coins_symbol: [],
  channels: []
}

window.FundingRateView = Backbone.View.extend({
  el: 'body',
  events: {
    'click button.funding-rate-segemented-subscriptions': function (e) {
      this.showSection('funding_rate_subscriptions', e)
    },
    'click button.funding-rate-segemented-positions': function (e) {
      this.showSection('funding_rate_positions', e)
    }
  },

  initialize(opts) {
    this.parentView = opts.parentView
    this.initCable()
  },

  initCable() {
    if (FundingRate.coins_symbol.length < 1) {
      return
    }

    for (let coin of Array.from(FundingRate.coins_symbol)) {
      FundingRate.channels.push(
        App.cable.subscriptions.create(
          {
            channel: 'FundingRateChannel',
            symbol: coin
          },
          {
            received: (data) => {
              return this.receivedData(data)
            }
          }
        )
      )
    }
  },

  receivedData(json) {
    $(`.funding_rate${json.id}_current_funding_rate`).html(
      `<span style="">${(parseFloat(json.funding_rate) * 100).toFixed(
        3
      )}%</span>`
    )

    $(`.funding_rate${json.id}_settled_funding_rate`).html(
      `<span style="">${(parseFloat(json.sett_funding_rate) * 100).toFixed(
        3
      )}%</span>`
    )

    var settle_time = parseInt(json.funding_time, 10)
    var settle_date = new Date(settle_time)

    var options = {
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    }
    var settle_time_local = new Intl.DateTimeFormat('en-US', options).format(
      settle_date
    )

    $(`.funding_rate${json.id}_settle_time`).html(
      `<span class="text-xs">${settle_time_local}</span>`
    )

    $(`.funding_rate${json.id}_last_price`).html(
      `<span class="">${json.last_price}</span>`
    )
  },

  showSection(segement, e) {
    const _el = $(e.target).closest('button')
    const btns = document.querySelectorAll(
      '#fundingRateSegmentedContent > button'
    )
    btns.forEach((btn) => {
      btn.classList.remove(
        'bg-white',
        'dark:bg-gray-700',
        'z-10',
        'text-yellow-300'
      )
    })
    _el.addClass('bg-white dark:bg-gray-700 z-10 text-yellow-300')

    const sections = document.querySelectorAll(
      '#fundingRateSegmentedSession > section'
    )
    sections.forEach((section) => {
      if (section.id === segement) {
        section.style.display = ''
      } else {
        section.style.display = 'none'
      }
    })
  }
})
