window.TradingAccountView = Backbone.View.extend({
  el: 'body',
  initialize(opts) {
    this.parentView = opts.parentView
    this.initCable()
  },
  initCable() {
    App.cable.subscriptions.create(
      { channel: 'TradingAccountChannel' },
      {
        received: (data) => {
          return this.receivedTradingAccount(data)
        }
      }
    )
  },

  receivedTradingAccount(json) {
    if (typeof json.message.balance !== 'undefined') {
      balance_overview = json.message.balance.overview
      $(`.account_${json.message.id}_total_usd`).html(
        `<span class="">${balance_overview.total_amount}</span>`
      )

      $(`.account_${json.message.id}_available_usdt`).html(
        `<span class="">${balance_overview.usdt_available}</span>`
      )
    }
  }
})
